import {
  ActionSection,
  Container,
  IconList,
  LeftContent,
  LogoCloud,
} from "blixify-ui-web/lib"
import React, { Component } from "react"
import { renderSVG, servicesContent, servicesContentList } from "."
import CustomHeader from "../components/base/CustomHeader"
import Footer from "../components/base/Footer"
import SEO from "../components/base/seo"
import Hero from "../components/marketing/Hero"
import LogoFirebase from "../images/logo_firebase.png"
import LogoMongoDB from "../images/logo_mongodb.png"
import LogoNextJS from "../images/logo_nextjs.png"
import LogoReact from "../images/logo_react.png"
import LogoReactNative from "../images/logo_reactnative.png"
import Digitalisation from "../images/about_blixify.png"
import { Link } from "gatsby"

const logoList = [
  { src: LogoReact, alt: LogoReact, href: "#" },
  { src: LogoReactNative, alt: LogoReactNative, href: "#" },
  { src: LogoNextJS, alt: LogoNextJS, href: "#" },
  { src: LogoFirebase, alt: LogoFirebase, href: "#" },
  { src: LogoMongoDB, alt: LogoMongoDB, href: "#" },
]

export default class Services extends Component {
  render() {
    return (
      <>
        <SEO
          title="Blixify - Services"
          description="Blixify is a software house that specializes in developing minimalistic and user-centric digital products. We offer development services such as web & mobile app development & IOT integration"
        />
        <div className="relative bg-gray-50 overflow-hidden">
          <CustomHeader />
          {renderSVG()}
          <Hero title="Services" />
        </div>
        <Container bgColor="bg-white" className="pb-10">
          <LeftContent
            className="my-12"
            title="Our Services"
            image={Digitalisation}
            imgContain={true}
            contentList={servicesContent}
            callToAction={<IconList iconList={servicesContentList} />}
          />
          <div className="py-12 pb-20 flex flex-col items-center text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            <p className="mb-5">Our Tech Stack</p>
            <LogoCloud logoList={logoList} />
          </div>
          <ActionSection
            title="Visit Our Portfolio"
            buttonLink="/portfolio"
            buttonText="Go to Portfolio"
            linkType="gatsby"
            custom={Link}
          />
        </Container>
        <Footer />
      </>
    )
  }
}
